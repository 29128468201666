import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { convertToIndianPriceFormat, getAbbreviation } from '../util/string-util';

const CustomerDetail = () => {

    const { id } = useParams();

    const [customer, setCustomer] = useState(null)
    const [customerList, setCustomerList] = useState([])

    const [errorMessage, setErrorMessage] = useState('')
    const navigate = useNavigate();

    const tempSignatureUrl = '/signature.png'

    useEffect(() => {
        try {
            const cList = JSON.parse(localStorage.getItem('c-list'))
            setCustomerList(cList)
            console.log(cList.find(c => c.customerId === +id));
            setCustomer(cList.find(c => c.customerId === +id))
        } catch (e) {

        }
    }, [id])


    const svgPdf = `<svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 9V3.5L18.5 9M6 2C4.89 2 4 2.89 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2H6Z" />
    </svg>`

    const onSubmit = () => {
        const tempCustomer = customerList.find(c => c.customerId === +id)
        tempCustomer.isApproved = 1
        setCustomerList([...customerList, tempCustomer])
        localStorage.setItem('c-list', JSON.stringify(customerList))

        setErrorMessage('Customer data approved')

    }

    const handleSnackBarClose = () => {
        setErrorMessage('')
        navigate(`/customer-list`)
    }

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleSnackBarClose}>
                OK
            </Button>
        </React.Fragment>
    );

    return (
        <>
            <Snackbar
                open={errorMessage !== ''}
                autoHideDuration={2000}
                onClose={handleSnackBarClose}
                message={errorMessage}
                action={action}
            />
            {customer &&
                <div className='bg-light kyc-wrapper pb-5'>
                    <div className='container'>
                        <Header title='Customer Onboarding - Digital KYC' />
                        <div className="customer-card lg bg-white rounded me-3 mb-3 p-3 mt-5">
                            <div className={'status ' + (customer.isApproved ? 'approved' : 'new')}></div>
                            <div className="d-flex align-items-center">
                                <div className='avatar'>
                                    {getAbbreviation(customer.accountName)}
                                </div>
                                <div className='ps-3'>
                                    <h5 className='mb-1'>
                                        {customer.accountName}
                                    </h5>
                                    <p className='mb-0 text-secondary fw-semibold'>
                                        {convertToIndianPriceFormat(customer.potential)}
                                    </p>
                                </div>
                            </div>
                            <p className='value mt-2 mb-2'>
                                {customer.customerGroupName}
                            </p>
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <p className='label mb-1'>Sales Person</p>
                                    <p className='value mb-0'>
                                        {customer.salesPersonName}
                                    </p>
                                    <p className='value mb-0 value-sm'>
                                        {customer.salesPersonEmail}
                                    </p>
                                    <p className='value mb-0 value-sm'>
                                        {customer.mobileNumber}
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-6 mt-3">
                                    <p className='label mb-0'>MSP Name</p>
                                    <p className='value mb-0 value-sm'>
                                        {customer.mspName}
                                    </p>
                                </div>
                                <div className="col-md-3 col-6 mt-3">
                                    <p className='label mb-0'>Region</p>
                                    <p className='value mb-0 value-sm'>
                                        {customer.region}
                                    </p>
                                </div>
                                <div className="col-md-3 col-6 mt-3">
                                    <p className='label mb-0'>cluster Name</p>
                                    <p className='value mb-0 value-sm'>
                                        {customer.clusterName}
                                    </p>
                                </div>
                                <div className="col-md-3 col-6 mt-3">
                                    <p className='label mb-0'>territor Name</p>
                                    <p className='value mb-0 value-sm'>
                                        {customer.territoryName}
                                    </p>
                                </div>
                                <div className="col-md-3 col-6 mt-3">
                                    <p className='label mb-0'>class Name</p>
                                    <p className='value mb-0 value-sm'>
                                        {customer.className}
                                    </p>
                                </div>
                                <div className="col-md-3 col-6 mt-3">
                                    <p className='label mb-0'>aladin</p>
                                    <p className='value mb-0 value-sm'>
                                        {customer.aladin}
                                    </p>
                                </div>
                                <div className="col-md-3 col-6 mt-3">
                                    <p className='label mb-1'>Gst</p>
                                    <p className='value mb-0 value-sm'>
                                        {customer.gstNo ? customer.gstNo : 'N/A'}
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 col-6 mt-3">
                                    <p className='label mb-0'>customer Address</p>
                                    <p className='value mb-0 value-sm'>
                                        {customer.customerAddress}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-wrap">
                            <div className="col-md-6 col pe-md-5 img-picker-wrapper">
                                <p className='mt-4 fw-medium mb-2 text-primary'>KYC</p>
                                <div className="d-flex align-items-center justify-content-between img-picker bg-white rounded p-3" >
                                    <div className="d-flex align-items-center w-100" >
                                        <div className='svg-wrapper p-3 rounded' dangerouslySetInnerHTML={{ __html: svgPdf }} />
                                        <p className='ps-3 mb-0 w-100'>
                                            {customer.kycUrl ? customer.kycUrl : 'Upload KYC'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col img-picker-wrapper">
                                <p className='mt-4 fw-medium mb-2 text-primary'>Aadhar</p>
                                <div className="d-flex align-items-center justify-content-between img-picker bg-white rounded p-3" >
                                    <div className="d-flex align-items-center w-100" >
                                        <div className='svg-wrapper p-3 rounded' dangerouslySetInnerHTML={{ __html: svgPdf }} />
                                        <p className='ps-3 mb-0 w-100'>
                                            {customer.aadharUrl ? customer.aadharUrl : 'Upload AADHAR'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col pe-md-5 img-picker-wrapper">
                                <p className='mt-4 fw-medium mb-2 text-primary'>PAN</p>
                                <div className="d-flex align-items-center justify-content-between img-picker bg-white rounded p-3" >
                                    <div className="d-flex align-items-center w-100" >
                                        <div className='svg-wrapper p-3 rounded' dangerouslySetInnerHTML={{ __html: svgPdf }} />
                                        <p className='ps-3 mb-0 w-100'>
                                            {customer.panUrl ? customer.panUrl : 'Upload PAN'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col img-picker-wrapper">
                                <p className='mt-4 fw-medium mb-2 text-primary'>Customer Credit</p>
                                <div className="d-flex align-items-center justify-content-between img-picker bg-white rounded p-3" >
                                    <div className="d-flex align-items-center w-100" >
                                        <div className='svg-wrapper p-3 rounded' dangerouslySetInnerHTML={{ __html: svgPdf }} />
                                        <p className='ps-3 mb-0 w-100'>
                                            {customer.customerCreditUrl ? customer.customerCreditUrl : 'Upload Customer Credit'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col pe-md-5 img-picker-wrapper">
                                <p className='mt-4 fw-medium mb-2 text-primary'>GST</p>
                                <div className='bg-white rounded p-3'>
                                    <div className="d-flex align-items-center justify-content-between img-picker mt-3" >
                                        <div className="d-flex align-items-center w-100" >
                                            <div className='svg-wrapper p-3 rounded' dangerouslySetInnerHTML={{ __html: svgPdf }} />
                                            <p className='ps-3 mb-0 w-100'>
                                                {customer.gstUrl ? customer.gstUrl : customer.hasGst ? 'Upload GST doc' : 'Upload GST declaration'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className='mt-4 fw-medium mb-2 text-primary'>Signature</p>
                        <div className='rounded d-flex flex-column bg-white' >
                            <img src={customer.signatureUrl ? customer.signatureUrl : tempSignatureUrl} width="320px" alt="" />
                        </div>

                        {
                            customer.isApproved === 1 ? <></> :
                                <div className="d-flex justify-content-end mx-0 pb-5" >
                                    <div>
                                        <Button variant="contained" onClick={onSubmit} className='mt-4 ms-auto px-4'>Approve</Button>
                                    </div>
                                </div>
                        }

                    </div>
                </div>

            }
        </>

    )
}

export default CustomerDetail
