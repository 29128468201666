import DigitalKyc from "./pages/digital-kyc";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CustomerDetail from "./pages/customer-detail";
import Success from "./pages/success";
import CustomerListCard from "./pages/customer-list-card";

function App() {

  if (!localStorage.getItem('c-list')) {
    localStorage.setItem('c-list', JSON.stringify([
      {
        "accountName": "ABC Company",
        "customerGroupName": "Group A",
        "salesPersonName": "John Doe",
        "salesPersonEmail": "john.doe@example.com",
        "mobileNumber": "1234567890",
        "gstNo": "GST12345",
        "potential": 613699,
        "mspName": "MSP Company",
        "region": "Region A",
        "clusterName": "Cluster 1",
        "territoryName": "Territory X",
        "className": "Class A",
        "aladin": "Aladin123",
        "customerAddress": "123 Main Street, City",
        "kycUrl": "ABC Company kyc.pdf",
        "aadharUrl": "ABC Company aadhar.pdf",
        "panUrl": "ABC Company pan.pdf",
        "gstUrl": "ABC Company gst.pdf",
        "gstOrDeclaration": "Declaration",
        "customerCreditUrl": "ABC Company customer credit.pdf",
        "isApproved": 0,
        "customerId": 1,
        "hasGst": 0,
      },
      {
        "accountName": "XYZ Corporation",
        "customerGroupName": "Group B",
        "salesPersonName": "Jane Smith",
        "salesPersonEmail": "jane.smith@example.com",
        "mobileNumber": "9876543210",
        "gstNo": "GST67890",
        "potential": 9820581,
        "mspName": "MSP Solutions",
        "region": "Region B",
        "clusterName": "Cluster 2",
        "territoryName": "Territory Y",
        "className": "Class B",
        "aladin": "Aladin456",
        "customerAddress": "456 Elm Street, Town",
        "kycUrl": "XYZ Corporation kyc.pdf",
        "aadharUrl": "XYZ Corporation aadhar.pdf",
        "panUrl": "XYZ Corporation pan.pdf",
        "gstUrl": "XYZ Corporation gst.pdf",
        "gstOrDeclaration": "GST",
        "customerCreditUrl": "XYZ Corporation customer credit.pdf",
        "isApproved": 1,
        "customerId": 2,
        "hasGst": 1
      },
      {
        "accountName": "PQR Ltd.",
        "customerGroupName": "Group C",
        "salesPersonName": "Alice Johnson",
        "salesPersonEmail": "alice.johnson@example.com",
        "mobileNumber": "5555555555",
        "gstNo": "GST54321",
        "potential": 3508381,
        "mspName": "MSP Solutions",
        "region": "Region C",
        "clusterName": "Cluster 3",
        "territoryName": "Territory Z",
        "className": "Class C",
        "aladin": "Aladin789",
        "customerAddress": "789 Oak Avenue, Village",
        "kycUrl": "PQR Ltd. kyc.pdf",
        "aadharUrl": "PQR Ltd. aadhar.pdf",
        "panUrl": "PQR Ltd. pan.pdf",
        "gstUrl": "PQR Ltd. gst.pdf",
        "gstOrDeclaration": "GST",
        "customerCreditUrl": "PQR Ltd. customer credit.pdf",
        "isApproved": 0,
        "customerId": 3,
        "hasGst": 0
      },
      {
        "accountName": "EFG Corp",
        "customerGroupName": "Group D",
        "salesPersonName": "Bob Anderson",
        "salesPersonEmail": "bob.anderson@example.com",
        "mobileNumber": "5551234567",
        "gstNo": "GST98765",
        "potential": 3262286,
        "mspName": "MSP Solutions",
        "region": "Region D",
        "clusterName": "Cluster 4",
        "territoryName": "Territory W",
        "className": "Class D",
        "aladin": "Aladin246",
        "customerAddress": "246 Pine Street, Village",
        "kycUrl": "EFG Corp kyc.pdf",
        "aadharUrl": "EFG Corp aadhar.pdf",
        "panUrl": "EFG Corp pan.pdf",
        "gstUrl": "EFG Corp gst.pdf",
        "gstOrDeclaration": "Declaration",
        "customerCreditUrl": "EFG Corp customer credit.pdf",
        "isApproved": 1,
        "customerId": 4,
        "hasGst": 1
      },
      {
        "accountName": "LMN Enterprises",
        "customerGroupName": "Group E",
        "salesPersonName": "Sarah Johnson",
        "salesPersonEmail": "sarah.johnson@example.com",
        "mobileNumber": "9876543210",
        "gstNo": "GST56789",
        "potential": 7448838,
        "mspName": "MSP Company",
        "region": "Region E",
        "clusterName": "Cluster 5",
        "territoryName": "Territory V",
        "className": "Class E",
        "aladin": "Aladin579",
        "customerAddress": "579 Maple Street, Town",
        "kycUrl": "LMN Enterprises kyc.pdf",
        "aadharUrl": "LMN Enterprises aadhar.pdf",
        "panUrl": "LMN Enterprises pan.pdf",
        "gstUrl": "LMN Enterprises gst.pdf",
        "gstOrDeclaration": "GST",
        "customerCreditUrl": "LMN Enterprises customer credit.pdf",
        "isApproved": 0,
        "customerId": 5,
        "hasGst": 0
      },
      {
        "accountName": "OPQ Corporation",
        "customerGroupName": "Group F",
        "salesPersonName": "David Lee",
        "salesPersonEmail": "david.lee@example.com",
        "mobileNumber": "1234567890",
        "gstNo": "GST23456",
        "potential": 6515943,
        "mspName": "MSP Solutions",
        "region": "Region F",
        "clusterName": "Cluster 6",
        "territoryName": "Territory U",
        "className": "Class F",
        "aladin": "Aladin468",
        "customerAddress": "468 Oak Street, City",
        "kycUrl": "OPQ Corporation kyc.pdf",
        "aadharUrl": "OPQ Corporation aadhar.pdf",
        "panUrl": "OPQ Corporation pan.pdf",
        "gstUrl": "OPQ Corporation gst.pdf",
        "gstOrDeclaration": "GST",
        "customerCreditUrl": "OPQ Corporation customer credit.pdf",
        "isApproved": 1,
        "customerId": 6,
        "hasGst": 1
      },
      {
        "accountName": "RST Ltd.",
        "customerGroupName": "Group G",
        "salesPersonName": "Emma Thompson",
        "salesPersonEmail": "emma.thompson@example.com",
        "mobileNumber": "5555555555",
        "gstNo": "GST76543",
        "potential": 2085848,
        "mspName": "MSP Solutions",
        "region": "Region G",
        "clusterName": "Cluster 7",
        "territoryName": "Territory T",
        "className": "Class G",
        "aladin": "Aladin357",
        "customerAddress": "357 Pine Avenue, Village",
        "kycUrl": "RST Ltd. kyc.pdf",
        "aadharUrl": "RST Ltd. aadhar.pdf",
        "panUrl": "RST Ltd. pan.pdf",
        "gstUrl": "RST Ltd. gst.pdf",
        "gstOrDeclaration": "Declaration",
        "customerCreditUrl": "RST Ltd. customer credit.pdf",
        "isApproved": 0,
        "customerId": 7,
        "hasGst": 0
      }
    ]))
  }

  // console.log([ ].map((c, index) => {
  //   return {
  //     ...c, kycUrl: `${c.accountName} kyc.pdf`,
  //     aadharUrl:`${c.accountName} aadhar.pdf`,
  //     panUrl:`${c.accountName} pan.pdf`,
  //     gstUrl:`${c.accountName} gst.pdf`,
  //     customerCreditUrl:`${c.accountName} customer credit.pdf`,
  //   }
  // }));

  // function generateRandomNumber() {
  //   const min = 100000; // 1 lakh
  //   const max = 10000000; // 1 crore

  //   const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  //   return randomNumber;
  // }

  // Example usage:
  // console.log(generateRandomNumber());

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DigitalKyc />} />
        <Route path="/customer-list" element={<CustomerListCard />} />
        <Route path="/customer-detail/:id" element={<CustomerDetail />} />
        <Route path="/success" element={<Success />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
