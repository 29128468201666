import React, { useRef, useState } from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import SignatureCanvas from 'react-signature-canvas';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Snackbar from '@mui/material/Snackbar';

const DigitalKyc = () => {

    const navigate = useNavigate()

    const textFieldProps = {
        variant: "outlined", size: "small", fullWidth: true
    }

    const signatureRef = useRef(null)
    const handleSignatureClear = () => {
        signatureRef.current.clear()
    }

    const [errorMessage, setErrorMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)

    const formInitState = {
        accountName: '',
        customerGroupName: '',
        salesPersonName: '',
        salesPersonEmail: '',
        mobileNumber: '',
        hasGst: true,
        gstNo: '',
        potential: '',
        mspName: '',
        region: '',
        clusterName: '',
        territoryName: '',
        className: '',
        aladin: '',
        customerAddress: '',
        kycUrl: '',
        aadharUrl: '',
        panUrl: '',
        gstUrl: '',
        customerCreditUrl: '',
        signatureUrl: '',
        isApproved: 0,
    }

    const [kycForm, setKycForm] = useState({ ...formInitState })

    const handleChange = (event) => {
        const { name, value } = event.target;
        setKycForm((prevValue) => ({ ...prevValue, [name]: value }));
    }

    const camelCaseToReadable = (camelCaseString) => {
        const result = camelCaseString.replace(/([A-Z])/g, ' $1')
        return result.charAt(0).toUpperCase() + result.slice(1)
    }

    const onSubmit = (event) => {
        event.preventDefault()

        for (const key of Object.keys(kycForm)) {

            if (key === 'hasGst' || key === 'customerCreditUrl' || key === 'signatureUrl' || key === 'isApproved') {
                continue
            }

            if ((key === 'gstNo' || key === 'gstUrl') && !kycForm.hasGst) {
                continue
            }

            if (!kycForm[key]) {
                setErrorMessage(`${camelCaseToReadable(key).replace('Url', 'Document')} cannot be empty`)
                return
            }

            if (key === 'salesPersonEmail' && !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(kycForm.salesPersonEmail))) {
                setErrorMessage(`Invalid ${camelCaseToReadable(key)}`)
                return
            }

            if (key === 'gstNo' && kycForm.hasGst && !(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(kycForm.gstNo))) {
                setErrorMessage(`Invalid ${camelCaseToReadable(key)}`)
                return
            }

            if (key === 'mobileNumber' && !(/^\d{10}$/.test(kycForm.mobileNumber))) {
                setErrorMessage(`Invalid ${camelCaseToReadable(key)}`)
                return
            }
            if (key === 'potential' && !(/^\d+(\.\d+)?$/.test(kycForm.potential))) {
                setErrorMessage(`Invalid ${camelCaseToReadable(key)}`)
                return
            }

        }

        if (signatureRef.current.isEmpty()) {
            setErrorMessage(`Signature cannot be empty`)
            return
        }
        const signatureDataUrl = signatureRef.current.toDataURL();

        const customerList = JSON.parse(localStorage.getItem('c-list'))
        customerList.unshift({ ...kycForm, signatureUrl: signatureDataUrl, customerId: customerList.length + 3 })
        localStorage.setItem('c-list', JSON.stringify(customerList))

        setOpenAlert(true)

    }

    const onDialogClose = () => {
        setOpenAlert(false)
        navigate(`/customer-list`,)
    }

    const svgAttach = `<svg   viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M544 864V672H672L512 480L352 672H480V864H320V862.4C314.624 862.72 309.504 864 304 864C240.348 864 179.303 838.714 134.294 793.706C89.2856 748.697 64 687.652 64 624C64 500.864 157.12 400.512 276.608 386.752C287.084 331.987 316.316 282.584 359.277 247.04C402.238 211.496 456.242 192.034 512 192C567.766 192.031 621.779 211.491 664.75 247.034C707.721 282.577 736.966 331.981 747.456 386.752C866.944 400.512 959.936 500.864 959.936 624C959.936 687.652 934.65 748.697 889.642 793.706C844.633 838.714 783.588 864 719.936 864C714.56 864 709.376 862.72 703.936 862.4V864H544Z" />
    </svg>
    `

    const svgPdf = `<svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 9V3.5L18.5 9M6 2C4.89 2 4 2.89 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2H6Z" />
    </svg>
    `

    const svgDelete = `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z" />
</svg>`

    const openExplorer = (event) => {
        const current = event.currentTarget
        current.querySelector('input').click()
    }

    const onFileChange = (e, key) => {
        //TODO : upload file and get url - Wilson
        setKycForm((prevValue) => ({ ...prevValue, [key]: e.target?.files[0]?.name }));
    }

    const deleteFile = (event, key) => {
        event.stopPropagation();
        setKycForm((prevValue) => ({ ...prevValue, [key]: '' }));
    }

    return (
        <>
            <Snackbar
                open={errorMessage !== ''}
                autoHideDuration={6000}
                onClose={e => setErrorMessage('')}
                message={errorMessage}
            />
            <Dialog
                open={openAlert}
                onClose={onDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                
                <DialogContent>
                    <div className='success-popup d-flex flex-column align-items-center justify-content-center p-md-5 p-2'>
                        <img src="/success.png" alt="" />
                        <h4 className='text-success mt-4'>Thank You</h4>
                        <p className='text-secondary text-center'>Your application has been submitted. Our team will get in touch with you soon.</p>
                        <a onClick={onDialogClose} className="text-secondary fw-bold" href="#">CONTINUE</a>
                    </div>
                </DialogContent>
                
            </Dialog>
            <div className='bg-light kyc-wrapper'>
                <div className='container'>
                    <Header title='Customer Onboarding - Digital KYC' />
                    {
                        (
                            kycForm && <>
                                <p className='mt-5 fw-medium mb-2 text-primary'>Basic Details</p>
                                <div className='bg-white p-md-4 p-3 rounded' >
                                    <div className="row">
                                        <div className="col-md-4 col-12 mt-md-4">
                                            <TextField {...textFieldProps} label="Account Name" value={kycForm.accountName} name="accountName" onChange={handleChange} />
                                        </div>
                                        <div className="col-md-4 col-12 mt-4">
                                            <TextField {...textFieldProps} label="Customer Group Name" value={kycForm.customerGroupName} name="customerGroupName" onChange={handleChange} />
                                        </div>
                                        <div className="col-md-4 col-12 mt-4">
                                            <TextField {...textFieldProps} label="Sales Person Name" value={kycForm.salesPersonName} name="salesPersonName" onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-12 mt-4">
                                            <TextField {...textFieldProps} label="Sales Person Email ID" value={kycForm.salesPersonEmail} name="salesPersonEmail" onChange={handleChange} />
                                        </div>
                                        <div className="col-md-4 col-12 mt-4">
                                            <TextField {...textFieldProps} label="Mobile No" value={kycForm.mobileNumber} name="mobileNumber" onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-md-4 col-12 mt-4">
                                            <TextField {...textFieldProps} label="Potential" value={kycForm.potential} name="potential" onChange={handleChange} />
                                        </div>
                                        <div className="col-md-4 col-12 mt-4">
                                            <TextField {...textFieldProps} label="MSP Name" value={kycForm.mspName} name="mspName" onChange={handleChange} />
                                            {/* <Autocomplete {...textFieldProps} label="MSP Name" value={kycForm.mspName}  name="mspName"  options={['COCHIN MSP', 'K MSP']} onChange={handleChange} renderInput={(params) => <TextField {...params} label="MSP Name" />} /> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-12 mt-4">
                                            <TextField {...textFieldProps} label="Region" value={kycForm.region} name="region" onChange={handleChange} />
                                        </div>
                                        <div className="col-md-4 col-12 mt-4">
                                            <TextField {...textFieldProps} label="Cluster Name" value={kycForm.clusterName} name="clusterName" onChange={handleChange} />
                                        </div>
                                        <div className="col-md-4 col-12 mt-4">
                                            <TextField {...textFieldProps} label="Territory Name" value={kycForm.territoryName} name="territoryName" onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-12 mt-4">
                                            <TextField {...textFieldProps} label="Class" value={kycForm.className} name="className" onChange={handleChange} />
                                            <TextField {...textFieldProps} label="Aladin" className='mt-4' value={kycForm.aladin} name="aladin" onChange={handleChange} />
                                        </div>
                                        <div className="col-md-8 col-12 mt-4">
                                            <TextField {...textFieldProps} label="Customer Address" multiline minRows={4} maxRows={4} value={kycForm.customerAddress} name="customerAddress" onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex flex-wrap">
                                    <div className="col-md-6 col pe-md-5 img-picker-wrapper">
                                        <p className='mt-4 fw-medium mb-2 text-primary'>KYC</p>
                                        <div className="d-flex align-items-center justify-content-between img-picker bg-white rounded p-3" >
                                            <div className="d-flex align-items-center w-100" onClick={openExplorer}>
                                                <div className='svg-wrapper p-3 rounded' dangerouslySetInnerHTML={{ __html: (kycForm.kycUrl ? svgPdf : svgAttach) }} />
                                                <p className='ps-3 mb-0 w-100'>
                                                    {kycForm.kycUrl ? kycForm.kycUrl : 'Upload KYC'}
                                                </p>
                                                <input type="file" accept=".pdf,.doc,.docx" onChange={(e => onFileChange(e, 'kycUrl'))} className='d-none' />
                                            </div>
                                            <div className="delete" onClick={(e) => deleteFile(e, 'kycUrl')}>
                                                <div dangerouslySetInnerHTML={{ __html: svgDelete }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col img-picker-wrapper">
                                        <p className='mt-4 fw-medium mb-2 text-primary'>Aadhar</p>
                                        <div className="d-flex align-items-center justify-content-between img-picker bg-white rounded p-3" >
                                            <div className="d-flex align-items-center w-100" onClick={openExplorer}>
                                                <div className='svg-wrapper p-3 rounded' dangerouslySetInnerHTML={{ __html: (kycForm.aadharUrl ? svgPdf : svgAttach) }} />
                                                <p className='ps-3 mb-0 w-100'>
                                                    {kycForm.aadharUrl ? kycForm.aadharUrl : 'Upload AADHAR'}
                                                </p>
                                                <input type="file" accept=".pdf,.doc,.docx" onChange={(e => onFileChange(e, 'aadharUrl'))} className='d-none' />
                                            </div>
                                            <div className="delete" onClick={(e) => deleteFile(e, 'aadharUrl')}>
                                                <div dangerouslySetInnerHTML={{ __html: svgDelete }} />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-6 col pe-md-5 img-picker-wrapper">
                                        <p className='mt-4 fw-medium mb-2 text-primary'>PAN</p>
                                        <div className="d-flex align-items-center justify-content-between img-picker bg-white rounded p-3" >
                                            <div className="d-flex align-items-center w-100" onClick={openExplorer}>
                                                <div className='svg-wrapper p-3 rounded' dangerouslySetInnerHTML={{ __html: (kycForm.panUrl ? svgPdf : svgAttach) }} />
                                                <p className='ps-3 mb-0 w-100'>
                                                    {kycForm.panUrl ? kycForm.panUrl : 'Upload PAN'}
                                                </p>
                                                <input type="file" accept=".pdf,.doc,.docx" onChange={(e => onFileChange(e, 'panUrl'))} className='d-none' />
                                            </div>
                                            <div className="delete" tabIndex={2} onClick={(e) => deleteFile(e, 'panUrl')}>
                                                <div dangerouslySetInnerHTML={{ __html: svgDelete }} />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-6 col img-picker-wrapper">
                                        <p className='mt-4 fw-medium mb-2 text-primary'>Customer Credit</p>
                                        <div className="d-flex align-items-center justify-content-between img-picker bg-white rounded p-3" >
                                            <div className="d-flex align-items-center w-100" onClick={openExplorer}>
                                                <div className='svg-wrapper p-3 rounded' dangerouslySetInnerHTML={{ __html: (kycForm.customerCreditUrl ? svgPdf : svgAttach) }} />
                                                <p className='ps-3 mb-0 w-100'>
                                                    {kycForm.customerCreditUrl ? kycForm.customerCreditUrl : 'Upload Customer Credit'}
                                                </p>
                                                <input type="file" accept=".pdf,.doc,.docx" onChange={(e => onFileChange(e, 'customerCreditUrl'))} className='d-none' />
                                            </div>
                                            <div className="delete" tabIndex={2} onClick={(e) => deleteFile(e, 'customerCreditUrl')}>
                                                <div dangerouslySetInnerHTML={{ __html: svgDelete }} />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-6 col pe-md-5 img-picker-wrapper">
                                        <p className='mt-4 fw-medium mb-2 text-primary'>GST</p>
                                        <div className='bg-white rounded p-3'>
                                            <div className="col-auto ps-0">
                                                <FormControlLabel
                                                    // labelPlacement="start"
                                                    control={<Switch
                                                        checked={kycForm.hasGst}
                                                        onChange={e => setKycForm(prevVal => ({ ...prevVal, hasGst: !kycForm.hasGst, gstNo: '', gstUrl: '' }))}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />} label="Have Gst ?" />

                                            </div>
                                            {kycForm.hasGst && <div className="col mt-3">
                                                <TextField {...textFieldProps} label="GST No" value={kycForm.gstNo} name="gstNo" onChange={handleChange} />
                                            </div>}
                                            <div className="d-flex align-items-center justify-content-between img-picker mt-3" >
                                                <div className="d-flex align-items-center w-100" onClick={openExplorer}>
                                                    <div className='svg-wrapper p-3 rounded' dangerouslySetInnerHTML={{ __html: (kycForm.gstUrl ? svgPdf : svgAttach) }} />
                                                    <p className='ps-3 mb-0 w-100'>
                                                        {kycForm.gstUrl ? kycForm.gstUrl : kycForm.hasGst ? 'Upload GST doc' : 'Upload GST declaration'}
                                                    </p>
                                                    <input type="file" accept=".pdf,.doc,.docx" onChange={(e => onFileChange(e, 'gstUrl'))} className='d-none' />
                                                </div>
                                                <div className="delete" tabIndex={2} onClick={(e) => deleteFile(e, 'gstUrl')}>
                                                    <div dangerouslySetInnerHTML={{ __html: svgDelete }} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className='mt-4 fw-medium mb-2 text-primary'>Signature</p>
                                <div className='rounded d-flex flex-column' >
                                    <div>
                                        <SignatureCanvas ref={signatureRef} penColor="black" canvasProps={{ width: 320, height: 200, className: 'signature-canvas bg-white rounded' }} />
                                    </div>
                                    {<p className='sign-clear' onClick={handleSignatureClear}>Clear</p>}
                                </div>

                                <div className="d-flex justify-content-end mx-0 pb-5" >
                                    <div>
                                        <Button variant="contained" onClick={onSubmit} className='mt-3 ms-auto px-4'>Submit</Button>
                                    </div>
                                </div>
                            </>
                        )
                    }

                </div>
            </div>
        </>

    )
}

export default DigitalKyc
