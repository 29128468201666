import React from 'react'

const Success = () => {
    return (
        <div className='bg-light kyc-wrapper pb-5'>
            <div className='container'>
                <h1 className='pt-5 text-primary'>
                    Thank you
                </h1>
                <div className='bg-white p-4 rounded mt-4' >
                    <h5>
                        your application has been submitted
                    </h5>
                </div>
            </div>
        </div>

    )
}

export default Success
