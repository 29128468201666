export function getAbbreviation(name) {
  const words = name.split(' ');
  let abbreviation = words.map(word => word.charAt(0)).join('');

  if (abbreviation.length > 2) {
    abbreviation = abbreviation.slice(0, 2);
  }

  return abbreviation.toUpperCase();
}

export function convertToIndianPriceFormat(price) {
  const options = {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  };


  return (+price).toLocaleString('en-IN', options)
}