import React from 'react'

const Header = ({ title }) => {
    return (
        <div className="d-flex align-items-center pt-4">
            <a href="/" className='d-block'>
                <img src="/zeiss-logo.jpg" className='logo' alt="logo" />
            </a>
            <h3 className='text-primary mb-0 ps-3'>
                {title}
            </h3>
        </div>
    )
}

export default Header
