import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';

import { convertToIndianPriceFormat, getAbbreviation } from '../util/string-util';

const CustomerListCard = () => {

    const navigate = useNavigate()

    const [customerList, setCustomerList] = useState([])

    useEffect(() => {
        setCustomerList(JSON.parse(localStorage.getItem('c-list')))
    }, [])

    const viewDetails = (customerId) => {
        navigate(`/customer-detail/${customerId}`,)
    }


    return (
        <div className='bg-light kyc-wrapper'>
            <div className='container'>
                <Header title='Customer Onboarding - Digital KYC' />
                <div className="row mx-0 mt-5">
                    {
                        customerList.map((customer, index) => {
                            return (
                                <div key={index} onClick={e => viewDetails(customer.customerId)} className="customer-card bg-white rounded me-3 mb-3 p-3">
                                    <div className={'status ' + (customer.isApproved ? 'approved' : 'new')}></div>
                                    <div className="d-flex align-items-center">
                                        <div className='avatar'>
                                            {getAbbreviation(customer.accountName)}
                                        </div>
                                        <div className='ps-3'>
                                            <h5 className='mb-1'>
                                                {customer.accountName}
                                            </h5>
                                            <p className='mb-0 text-secondary fw-semibold'>
                                                {convertToIndianPriceFormat(customer.potential)}
                                            </p>
                                        </div>
                                    </div>
                                    <p className='value fw-bold mt-2 mb-2'>
                                        {customer.customerGroupName}
                                    </p>
                                    <div className="d-flex flex-column">
                                        <p className='label mb-0'>Sales Person</p>
                                        <p className='value mb-0'>
                                            {customer.salesPersonName}
                                        </p>
                                        <p className='value mb-0 value-sm'>
                                            {customer.salesPersonEmail}
                                        </p>
                                        <p className='value mb-0 value-sm'>
                                            {customer.mobileNumber}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default CustomerListCard
